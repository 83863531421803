import {  useEffect, useState } from 'react';
import { Form, Formik, useFormik } from 'formik';
// material
import { TableCell, TextField, Typography} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MenuItem from '@mui/material/MenuItem';

// component
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import {API_URL, FI_TYPE} from '../constant';
import ConfirmDialog from '../components/confirmDialog';
import { useSnackbar } from 'notistack';
import { isEmpty} from 'lodash';

// ----------------------------------------------------------------------

export default function PreScreeningFiQuestion(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [formvalue, setFormValue] = useState({})
  const [open, setOpen] = useState(false)
  const [prescreeningFiAnswers,setPrescreeningFiAnswers] = useState([])
  const [disable,setDisable]=useState(false)
  const [prescreeningFiQuestions,setPrescreeningFiQuestions] = useState([])
  const formik = useFormik({
    initialValues: {
        remarks: '',
        loan_id: props?.data?.loan_id,
        relation_id:0,
        customer_id: props?.data?.loan_id,
        fi_question_group: '',
        location: null,
        mortgage_id: null
    },

    onSubmit: async (value) => {
      setFormValue({...value})
      setOpen(true)
    },
  });

  useEffect(() => {
    async function getPrescreeningFiQuestions(){
        if(props?.data?.loan_id){
            const res= await axios.get(`${API_URL}/loan/${props?.data?.loan_id}/fi_questions`)
            if(res?.data?.status === 'success'){
                const prescreeningFiQuestions = res?.data?.data
                setPrescreeningFiQuestions(prescreeningFiQuestions);
            }
        }
    }
    getPrescreeningFiQuestions()
  },[props?.data?.loan_id])

  useEffect(() => {
    async function getPrescreeningFiAnswers(){
        if(props?.data?.customer_id && props?.data?.loan_id){
            const res= await axios.get(`${API_URL}/customer/${props?.data?.customer_id}/loan/${props?.data?.loan_id}/fi_values`)
            if(res?.data?.status === 'success'){
                const prescreeningFiAnswers = res?.data?.data
                setPrescreeningFiAnswers(prescreeningFiAnswers);
            }
        }
    }
    getPrescreeningFiAnswers()
  },[props?.data?.loan_id])
  
  async function handleSubmit(value){  
    const formDataArray = prescreeningFiQuestions.map((question) => ({
      customer_id: props?.data?.customer_id,
      loan_id: props?.data?.loan_id,
      fi_question_id: question.fi_question_id,
      fi_value: String(value[question.fi_question_id]),
      fi_question_group:'',
      remarks:'',
      relation_id:0,
      location: null,
      mortgage_id:null
    }));
    try{
      const response = await axios.post(`${API_URL}/users/${props?.data?.user_id}/leads/${props?.data?.lead_id}/fi/values?fi_type=${FI_TYPE.PRE_SCREENING_FI_TYPE}`,formDataArray);
      if(!isEmpty(response?.data?.data?.error)){
        enqueueSnackbar(response?.data?.data?.error, { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
        setDisable(false)
      }
      else if(response?.data?.status === 'success'){        
        setDisable(false)
        enqueueSnackbar('Pre Screening Fi successfuly save', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
        props.onClose()
      }else{
        enqueueSnackbar(response?.data?.data?.message, { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
        setDisable(false)
      }
    }
    catch(err){
      enqueueSnackbar(err?.message, { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
      props.onClose()
      setDisable(false)
    }
  }

  const submitFormValue = () => {
    setDisable(true)
    handleSubmit(formvalue)
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const generateInitialValues = () => {
    const initialValues = {};
    prescreeningFiQuestions.forEach((question) => {
      initialValues[question.fi_question_id] = ''; // Initialize as empty string
    });
    return initialValues;
  };
  

  const mapApiValuesToFormik = (apiData) => {
    const values = generateInitialValues();
    apiData.forEach((item) => {
      if ((values[item.fi_question_id])!==undefined) {
        values[item.fi_question_id] = item.fi_value; // Set the value from API to the form
      }
    });
    return values;
  };

  return (
    <div style={{margin:50}}>
      <ConfirmDialog open={open} onClose={closeDialog} submit={submitFormValue} close={closeDialog} />
      <Typography style={{textAlign:'center'}} variant="h4" gutterBottom>
        Pre Screening Fi Questions
      </Typography>
      <Grid container  style={{paddingLeft:50,paddingTop:30}} boxShadow={false} spacing={2} justifyContent='center' columns={{ xs: 3, sm: 54 }}>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
              Name : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.name}</h4>
            </Typography></TableCell>
        </Grid>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
              Lead Type : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.lead_type}</h4>
            </Typography></TableCell>
        </Grid>
        <Grid item xs={18} sx={{display:'flex', width:'max-content'}}>
            <TableCell sx={{display:'contents'}}><Typography style={{display:'flex',fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
              Loan Id : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {props?.data?.loan_id}</h4>
            </Typography></TableCell>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        initialValues={prescreeningFiAnswers ? mapApiValuesToFormik(prescreeningFiAnswers) : generateInitialValues()}
        onSubmit={handleSubmit}
      >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2} justifyContent="center">
            {prescreeningFiQuestions.map((question, index) => (
              <Grid item xs={11} sm={6} key={index}> 
                {question.input_type === 'dropdown' ? (
                  <TextField
                    fullWidth
                    select
                    id={`question_${question.fi_question_id}`}
                    name={`${question.fi_question_id}`}
                    label={question.fi_question}
                    value={(formik.values[question.fi_question_id]|| '')}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[question.fi_question_id] &&
                      Boolean(formik.errors[question.fi_question_id])
                    }
                    helperText={
                      formik.touched[question.fi_question_id] &&
                      formik.errors[question.fi_question_id]
                    }
                  >
                    {Object.values(question.fi_answers).map((value, i) => (
                      <MenuItem key={i} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    fullWidth
                    type={question.input_type}
                    id={`question_${question.fi_question_id}`}
                    name={`${question.fi_question_id}`}
                    label={question.fi_question}
                    value={formik.values[question.fi_question_id]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[question.fi_question_id] &&
                      Boolean(formik.errors[question.fi_question_id])
                    }
                    helperText={
                      formik.touched[question.fi_question_id] &&
                      formik.errors[question.fi_question_id]
                    }
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ m: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8} sm={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={disable}
              loading={formik.isSubmitting}
            >
            Submit
            </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Form>
      )}
      </Formik>
    </div>
  );
}