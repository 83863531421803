import {ToWords} from 'to-words'

export const API_URL = `/api/v0.1.1`

export const companyId = 1

export const NumberToWordConverter = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
    }
});

export const windowSize = window.innerWidth;

export const MOBILE_REGEX = /^([0-9]){10}?$/
export const AADHAAR_REGEX = /^([0-9]){4}([0-9]){4}([0-9]){4}?$/
export const PAN_REGEX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
export const NAME_REGEX = /^([a-zA-Z])([a-z" "A-Z])*$/

export const TYPE = {
    INVOICE: 'invoice',
    BILL: 'bill',
    ORDER: 'order',
    QUOTATION: 'quotation',
    RETURN: 'return'
}

export const STATE = {
    SUBMIT: 'submit',
    DRAFT: 'draft'
}

export const CATEGORY = {
    MANUFACTURER: 'MANUFACTURER',
    DEALER: 'DEALER',
    DISTRIBUTER: 'DISTRIBUTER',
	DSA : 'DSA'
}

export const FILE_UPLOAD_TYPE = {
	AADHAR: 'aadhar',
	PAN: 'pan',
	VOTER_ID : 'voter_id',
	BANK_STATEMENT: 'bank_statement',
	ITR: 'itr',
	SALARY: 'salary',
	CIBIL: 'cibil',
	PROPERTY: 'property',
	TECHNICAL_VALUATION: 'technical_valuation',
	LEGAL_VALUATION: 'legal_valuation',
	LEGAL_INTIMATION: "legal_intimation",
	LEGAL_VETTING: 'legal_vetting',
	PHONE_BILL: 'phone_bill',
	ELECTRICITY_BILL: 'electricity_bill',
	OTHER_ID_PROOF: 'other_id_proof',
	VEHICLE_LOAN_DOC: 'vehicle_loan_doc',
	DRIVING_LICENCE: 'driving_licence',
	VEHICLE_RC: 'vehicle_rc',
	GST_RETURN: 'gst',
	KACCHA_BILL: 'kachha_bill',
	CAM: 'cam',
	SALES_PHOTOS: 'sales_photos',
	PD_PHOTOS: 'pd_photos',
	AGREEMENT_PHOTOS: 'agreement_photos',
	RCU: 'rcu',
	OTHER_DOCS: 'other_documents',
	SANCTION_LETTER: 'sanction_letter',
	CERSAI_CHECK: 'cersai_check',
	CERSAI_ADD: 'cersai_add',
	SIGNED_DOCKET: 'signed_docket',
	INSURANCE: 'insurance',
	SIGNED_AGREEMENT_PHOTOS: 'signed_agreement_photos',
	FORE_CLOSURE: 'fore_closure',
	CLOSURE_RECEIPT: 'closure_receipt',
	LEDGER_RECEIPT: 'ledger_receipt',
	WELCOME_LETTER: 'welcome_letter',
	INSURANCE_POLICY: 'insurance_policy',
	LOD_LETTER: 'lod_letter',
	PROFILE_PHOTO: 'profile_photo',
    GENERATED_CAM: 'generated_cam',
	PDC_CHEQUE: 'pdc_cheque',
	PD_SHEET: 'pd_sheet',
	ACCOUNT_STATEMENT: 'account_statement',
	RATION_CARD: 'ration_card',
	TVR_RECORDING: 'tvr_recording',
	EMI_SCHEDULE: 'emi_schedule',
	FI_PHOTOS: 'fi_photos',
	FI_REPORT:'sales_observation_sheet',
	SALES_OBSERVATION_SHEET: 'sales_observation_sheet',
	NACH: 'nach',
	SIGNED_SANCTION_LETTER: 'signed_sanction_letter',
	BSV: 'bsv',
	LOD: 'lod',
	SOA: 'soa',
	UDHYOG_AADHAR: 'udhyog_aadhar',
	CAM_GENERATE: 'generated_cam',
	PATTA_VERIFICATION_PHOTOS : 'patta_verification_photos',
	VEHICLE_INSURANCE : 'vehicle_insurance',
	VEHICLE_INVOICE :  'vehicle_invoice',
	VEHICLE_PHOTOS: 'vehicle_photos',
	VEHICLE_TECHNICAL_VALUATION: 'vehicle_technical_valuation',
	DELIVERY_ORDER: 'delivery_order',
	TRANSUNION_Cibil : 'transunion_cibil',
	E_CHALLAN: 'e_challan',
	UPDATED_DAILY_LEDGER_REPORT :'updated_daily_ledger_report',
	VEHICLE_QUOTATION:`vehicle_quotation`,
	NOC:`noc`,
	LEDGER_PAYMENT_MODE :`ledger_payment_mode`,
	VERIFIED_ONLINE_SCREENSHOT : 'verified_online_screenshot',
	DSA_DOCUMENT : 'dsa_document',
	STAFF_DOCUMENT : 'staff_document',
	DEALER_DOCUMENT : 'dealer_document',
	LEDGER_STATEMENT: 'ledger_statement',
	SIGNING_PHOTOS :'signing_photos',
	REGISTERED_MORTGAGE : 'registered_mortgage',
	TRIP_PAYMENTS : 'trip_payments',
	METER_READING : 'meter_reading',
	LETTER_COPY : 'letter_copy',
	POST_RECEIPT : 'post_receipt',
	LETTER_TRACKING_DOCS : 'letter_tracking_docs',
	SURRENDER_LETTER : 'surrender_letter',
	APPLICATION_FORM : 'application_form',
	DEALER_LEDGER_DOCS : 'dealer_ledger_docs',
	OFFER_LETTER: 'offer_letter',
	STAFF_EDUCATION: 'staff_education',
	SALARY_SLIP: 'salary_slip',
	RELIEVING_LETTER: 'relieving_letter',
	BACKGROUND_CHECK_CONSENT_LETTER: 'bg_check_consent_letter',
	EMPLOYMENT_AGREEMENT_LETTER: 'employment_agreement_letter',
	PHOTO: 'photo',
	JOINING_DOCS: 'joining_docs',
	ASSET_HANDOVER_FORM: 'asset_handover_form',
	CHEQUE_MEMO: 'cheque_memo',
	WATER_BILL: 'water_bill',
	EBIKE_CIBIL: 'ebike_cibil',
	EBIKE_BANK_STATEMENT: 'ebike_bank_statement',
	DEALER_KYC_AADHAR: 'dealer_kyc_aadhar',
	DEALER_KYC_PAN: 'dealer_kyc_pan',
	DEALER_GST_REGISTRATION: 'dealer_gst_registration',
	DEALER_GSTR_3B: 'dealer_gstr_3b',
	DEALER_GSTR1: 'dealer_gstr1',
	DEALER_TC: 'dealer_tc',
	DEALER_ICAT: 'dealer_icat',
	DEALER_LOI: 'dealer_loi',
	DEALER_CHEQUE_CANCELLED: 'dealer_cheque_cancelled',
	DEALER_SECURITY_CHEQUE: 'dealer_security_cheque',
	DEALER_ELECTRICITY_BILL: 'dealer_electricity_bill',
	DEALER_RENT_AGREEMENT: 'dealer_rent_agreement',
	DEALER_OUTLET_PHOTOS: 'dealer_outlet_photos',
	DEALER_ITR: 'dealer_itr',
	DEALER_UDHYOG_AADHAR: 'dealer_udhyog_aadhar',
	DEALER_AGREEMENT: 'dealer_agreement',
	ALL_DEALER_DOCS: 'all_dealer_docs',
	VEHICLE_NOC: 'vehicle_noc',
	VEHICLE_NOC_FORM_35: 'vehicle_noc_form_35',
	BOUNCING_SLIP: 'bouncing_slip',
	LEDGER_ONLINE_RECEIPT: 'ledger_online_receipt',
	ESIGN_AGREEMENT : 'esign_agreement',
	OTHER_KYCS: 'other_kycs',
	MODEL_LIST: 'model_list',
	GENERATED_MAS_CAM:'generated_mas_cam',
	DEALER_BRANCH_ELECTRICITY_BILL: 'dealer_branch_electricity_bill',
	DEALER_BRANCH_RENT_AGREEMENT: 'dealer_branch_rent_agreement',
	DEALER_BRANCH_OUTLET_PHOTOS: 'dealer_branch_outlet_photos',
	SCREEN_SHOT: 'screen_shot',
	REMOTE_FILE_DOCS : 'remote_file_docs',
	GSTR : 'gst',
	BALANCE_SHEET : 'balance_sheet',
	VEHICLE_QUOTATION : 'vehicle_quotation',
	AGREEMENT : 'agreement',
	BATTERY_PHOTOS:'battery_photos',
	RENT_AGREEMENT : 'rent_agreement',
	TAX_RECEIPT : 'tax_receipt',
	NACH_SIGNING_PHOTO : 'nach_signing_photos',
	VEHICLE_CAM : 'vehicle_cam',
	VEHICLE_VIABILITY : 'vehicle_viability',
	DEALER_LEDGER_STATEMENT:'dealer_ledger_statements',
	DEALER_RC_LEDGER:'dealer_rc_ledger',
	DEALER_SECURITY_LEDGER:'dealer_security_ledger',
	BATTERY_WARRANTY_CARD:'battery_warranty_card',
	LOAN_AGREEMENT:'loan_agreement',
	BATTERY_INVOICE : 'battery_invoice',
	SALE_DEED:'sale_deed',
	MARGIN_MONEY_RECEIPT:'margin_money_receipt',
	GUARANTOR_SALES_PHOTO:'guarantor_sales_photo',
	COAPPLICANT_APPLICATION_FORM:'coapplicant_application_form',
	GUARANTOR_APPLICATION_FORM:'guarantor_application_form'
}

export const LOAN_TYPE = {
    DISBURSED_LOANS: 'disbursed_loans'
}

export const RELATION_TYPE = {
	PRIMARY: 'primary',
	COAPPICANT :'co-applicant',
	REFERENCE : 'reference',
	NOMINEE : 'nominee',
	GUARANTOR : 'guarantor',
}

export const CO_APPLICANT_TYPES = {
    FINANCIAL: "financial",
    NON_FINANCIAL: "non-financial",
  };

export const RELATIONS=[{label:'Husband' , value:'husband'},
  {label:'Wife' , value:'wife'},
  {label:'Son' , value:'son'},
  {label:'Daughter' , value:'daughter'},
  {label:'Father' , value:'father'},
  {label:'Mother' , value:'mother'},
  {label:'Brother' , value:'brother'},
  {label:'Sister' , value:'sister'},
  {label:'Uncle' , value:'uncle'},
  {label:'Aunty' , value:'aunty'},
  {label:'Friend' , value:'friend'},
  {label:'Nephew' , value:'nephew'},
  {label:'Niece' , value:'niece'},
  {label:'Father In Law' , value:'father-in-law'},
  {label:'Mother In Law' , value:'mother-in-law'},
  {label:'Brother In Law' , value:'brother-in-law'},
  {label:'Sister In Law' , value:'sister-in-law'},
  {label:'Daughter In Law' , value:'daughter-in-law'},
  {label:'Neighbour' , value:'neighbour'},
  {label:'Office Colleague' , value:'colleague'},];

  export const RELATIONS_FILE_UPLOAD_TYPE = [
	FILE_UPLOAD_TYPE.AADHAR,
	FILE_UPLOAD_TYPE.PAN,
	FILE_UPLOAD_TYPE.VOTER_ID,
	FILE_UPLOAD_TYPE.DRIVING_LICENCE,
	FILE_UPLOAD_TYPE.BANK_STATEMENT,
	FILE_UPLOAD_TYPE.ELECTRICITY_BILL,
	FILE_UPLOAD_TYPE.RATION_CARD,
	FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS,
	FILE_UPLOAD_TYPE.NACH,
	FILE_UPLOAD_TYPE.PROFILE_PHOTO,
	FILE_UPLOAD_TYPE.OTHER_DOCS,
	FILE_UPLOAD_TYPE.TVR_RECORDING,
	FILE_UPLOAD_TYPE.NACH_SIGNING_PHOTO,
	FILE_UPLOAD_TYPE.WATER_BILL,
]

export const OTHER_DOCUMENTS_FILE_TYPE = {
	RENT_AGREEMENT : 'rent_agreement',
	TAX_RECEIPT : 'tax_receipt',
	SIGNING_PHOTOS : 'signing_photos',
}

  export const LEAD_FILE_UPLOAD_TYPE = [
	...RELATIONS_FILE_UPLOAD_TYPE,
	FILE_UPLOAD_TYPE.VEHICLE_RC,
	FILE_UPLOAD_TYPE.APPLICATION_FORM,
	FILE_UPLOAD_TYPE.FI_REPORT,
	FILE_UPLOAD_TYPE.VEHICLE_INVOICE,
	FILE_UPLOAD_TYPE.VEHICLE_INSURANCE,
	FILE_UPLOAD_TYPE.SALES_PHOTOS,
	// FILE_UPLOAD_TYPE.SALES_OBSERVATION_SHEET,
	FILE_UPLOAD_TYPE.VEHICLE_PHOTOS,
	FILE_UPLOAD_TYPE.GSTR,
	FILE_UPLOAD_TYPE.ITR,
	FILE_UPLOAD_TYPE.UDHYOG_AADHAR,
	FILE_UPLOAD_TYPE.OTHER_KYCS,
	FILE_UPLOAD_TYPE.BALANCE_SHEET,
	FILE_UPLOAD_TYPE.PDC_CHEQUE,
	FILE_UPLOAD_TYPE.VEHICLE_QUOTATION,
	FILE_UPLOAD_TYPE.AGREEMENT,
	FILE_UPLOAD_TYPE.SIGNED_AGREEMENT_PHOTOS,
	FILE_UPLOAD_TYPE.BATTERY_PHOTOS,
	FILE_UPLOAD_TYPE.RENT_AGREEMENT,
	FILE_UPLOAD_TYPE.TAX_RECEIPT,
	FILE_UPLOAD_TYPE.BSV,
	FILE_UPLOAD_TYPE.VEHICLE_CAM,
	FILE_UPLOAD_TYPE.VEHICLE_VIABILITY,
  ]

  export const LOAN_SANCTIONED_AND_ABOVE = ['loan_sanctioned','loan_disbursed','repo','pending_repo','legal', 'recovery','foreclosed','loan_closed']

  export const LOAN_STATES = {
	PENDING: 'pending',
	FILE_LOGIN: 'file_login',
	CREDIT_ANALYSIS: 'credit_analysis',
	UNDER_FI: 'under_fi',
	UNDER_PD: 'under_pd',
	PENDING_DOCKET_SIGN: 'pending_docket_sign',
	PENDING_DISBURSEMENT: 'pending_disbursement',
	FORECLOSED: 'foreclosed',
	LOAN_CLOSED: 'loan_closed',
	LOAN_ON_HOLD: 'loan_onhold',
	LOAN_CANCELLED: 'loan_cancelled',
	LOAN_REJECTED: 'loan_rejected',
	LOAN_SANCTIONED: 'loan_sanctioned',
	LOAN_DISBURSED: 'loan_disbursed',
	INITIATE_FI: 'initiate_fi',
	LEGAL: 'legal',
	REPO: 'repo',
	PENDING_REPO: 'pending_repo',
	RECOVERY: 'recovery',
	TAT_REJECTED : 'tat_rejected',

	CREDIT_DECISION: 'credit_decision',
	PENDING_DOCUMENTS: 'pending_documents',
	// PENDING_VALUATION: 'pending_valuation',
	INITIATE_VALUATION: 'initiate_valuation',
	PENDING_SANCTION: 'pending_sanction',
	FILE_CHECK: 'file_check',
	PDD:'pdd',
	
	CIBIL_REQUESTED:'cibil_requested',
	CIBIL_GENERATED:'cibil_generated',
	INSURANCE : 'insurance'
}

export const LETTER_TYPE = {
	SANCTION_LETTER : 'sanction_letter',
	DELIVERY_ORDER : 'delivery_order'
}

export const LEAD_TYPE = {
	E_RICKSHAW : 'E-Rickshaw',
	E_BIKE  : 'E-Bike',
	L5 : 'L5',
	VEHICLE_BATTERY_LOAN:'Battery'
}

export const DEALER_LEDGER_MAIN_TYPES = {
	OPENING_BALANCE: "opening balance",
	DEALER_PAYMENT: "dealer payment",
	TA: "ta",
	DEALER_LOAN_PAYMENT: 'dealer loan payment',
	OPENING_BALANCE_APRIL_2023:'opening_balance_april_2023',
	RC_PAYMENT_DUE : 'rc payment due',
	PAYMENT_FROM_DEALER : 'payment from dealer',
	RC_PAYMENT: "rc payment",
	DEALER_CLAWBACK:"dealer clawback",
	DEALER_SECURITY_HOLD_DUE: "dealer security hold due",
	DEALER_SECURITY_PAYMENT_DUE: "dealer security payment due",
	RC_OPENING_BALANCE_APRIL_2023:'rc_opening_balance_april_2023',
	SECURITY_OPENING_BALANCE_APRIL_2023:'security_opening_balance_april_2023',
}

export const SECURITY_LEDGER_TYPES = {
	SECURITY_REFUNDED_AGAINST_TA:"security_refunded_against_ta",
	DEALER_SECURITY_HOLD_RETURN: "dealer security hold return",
	DEALER_SECURITY_PAYMENT_RETURN: "dealer security payment return",
	DEALER_SECURITY_HOLD_RELEASED: "dealer security hold released",
	DEALER_SECURITY_PAYMENT_RELEASED: "dealer security payment released",
	DEALER_SECURITY_HOLD:'dealer security hold',
	DEALER_SECURITY_PAYMENT: "dealer security payment",
	SECURITY_CLAWBACK:"security clawback",
};

export const RC_LEDGER_TYPES = {
	RC_SECURITY_HOLD_RETURN: "rc security hold return",
	RC_SECURITY_RELEASED: 'rc security released',
	RC_SECURITY_ADJUSTED:'rc security hold',
	RC_CLAWBACK:"rc clawback"
}

export const DEALER_PAYMENT_PURPOSE = {
	RC_PAYMENT: 'rc payment',
	LOAN_PAYMENT:'loan payment',
	DEALER_SECURITY_PAYMENT:'dealer security payment',
	DEALER_SECURITY_HOLD:'dealer security hold',
	DEALER_SECURITY_HOLD_RELEASED: "dealer security hold released",
	DEALER_SECURITY_PAYMENT_RELEASED: "dealer security payment released",
	RC_SECURITY_RELEASED: 'rc security released',
	DEALER_LOAN_PAYMENT: 'dealer loan payment',
	DEALER_SECURITY_HOLD_DUE: "dealer security hold due",
	DEALER_SECURITY_PAYMENT_DUE: "dealer security payment due",
  }

export const VEHICLE_REPO_STATUS={
	OPEN:'open',
	PENDING_SALE_APPROVAL : 'pending sale approval',
	RELEASE_APPROVED:'release approved',
	RELEASED:'released',
	SALE_APPROVED:'sale approved', 
	RETURNED:'returned',
	SALE:'sale',
	PENDING_RELEASE_APPROVAL:'pending release approval',
	APPROVE_SALE:'approve sale',
	RETURN:'return',
	PENDING_RETURN_APPPROVAL:'pending return approval',
	RETURN_APPROVED:'return approved'
}

export const NACH_BANK = {
	YES : 'YES BANK',
	IDFC : 'IDFC BANK',
	AU   : 'AU BANK'
}

export const NACH_STATUS ={
	ACTIVE : 'active',
	REJECT : 'reject',
	ACK : 'ack'
}

export const TVR_TYPE = {
	PRIMARY : 'Primary',
	CO_APPLICANT   : 'Co-Applicant',
	GUARANTOR : 'Guarantor'
}

export const BATTERY_WARRANTY = {
	12: 12,
	15: 15,
	18: 18,
	24: 24,
	36: 36,
	48: 48
}  

export const FI_TYPE ={
	PRE_SCREENING_FI_TYPE:'pre-screening-fi'
}