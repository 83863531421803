import React,{useContext,useEffect,useState} from 'react'
import * as yup from 'yup';
import {useFormik} from 'formik';
import {Autocomplete, Button, ClickAwayListener, Dialog, FormControl, InputLabel, Select, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Styles from '../../../../css/dates.module.css'
import {LoginContext} from '../../../../context/logincontext';
import axios from 'axios';
import {API_URL, CATEGORY, CO_APPLICANT_TYPES, LEAD_TYPE, NumberToWordConverter, RELATIONS, RELATION_TYPE} from '../../../../constant';
import ConfirmDialog from '../../../../components/confirmDialog';
import {LoadingButton} from '@mui/lab';
import {LeadContext} from '../../../../context/leadContext';
import {useNavigate } from 'react-router-dom';
import { includes, isEmpty, omit, set, upperCase } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import AddModel from '../../../AddParts'

const phoneRegExp = /^([0-9]){10}?$/

const validationSchema = yup.object({
    mobile: yup.string().matches(phoneRegExp,'Phone number is not valid').required('field cannot be empty').nullable(true),
    name: yup.string().required('field cannot be empty').nullable(true),
    dob: yup.date().required('This field is required').nullable(true),
    gender: yup.number().required('This field is required').nullable(true),
    lead_type: yup.string().required('This field is required').nullable(true),
    vehicle_name: yup.string().required('This field is required').nullable(true),
    exshowroom_price: yup.string().required('This field is required').nullable(true),
});

const relationValidationSchema = yup.object({
    mobile: yup.string().matches(phoneRegExp,'Phone number is not valid').required('field cannot be empty').nullable(true),
    name: yup.string().required('field cannot be empty').nullable(true),
    dob: yup.date().required('This field is required').nullable(true),
    gender: yup.number().required('This field is required').nullable(true),
});

function Index(props) {
    const context = useContext(LoginContext)
    const {leadId,setLeadId,setAlignment,setMeassage,setSeverity,setIsOpen,customerRelationData, setCustomerRelationData} = useContext(LeadContext)
    const { enqueueSnackbar } = useSnackbar();
    const [part,setPart] = useState([])
    const [open,setOpen] = useState(false)
    const [formvalue,setFormValue] = useState({})
    const [data,setData] = useState({})
    const navigate =useNavigate()
    const [relation ,setRelation] = useState({})
    const [relationError, setRelationError] = useState('')
    const [dsaDealer, setDsaDealer] = useState([])
    const [dealer, setDealer] = useState({})
    const [isAddProduct, setIsAddPropduct] = useState(false)
    
    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            tentative_purchase_date:null,
            vehicle_name: '',
            dob: null,
            exshowroom_price: '',
            gender: 1,
            user_id: context?.user?.user_id,
            company_id: context?.user?.company_id,
            dealer_id: context?.user?.company?.dealer_id,
            lead_type: isEmpty(props.name) ? 'E-Rickshaw' : '',
            type : '',
            source:'',
            deal_condition:'',
            payment_mode:''
        },
        validationSchema: isEmpty(props.name) ?  validationSchema : relationValidationSchema,
        onSubmit: async (value) => {
            if(isEmpty(props.name)){
                if(dealer?.dealer_id){
                    value.dealer_id = dealer?.dealer_id
                }
                setFormValue({...value})
                setOpen(true)
            }else{
                if(!isEmpty(relation)){
                    let data = omit(value,['tentative_purchase_date','vehicle_name','exshowroom_price'])
                    if(includes(Object.values(RELATION_TYPE),props.name)){
                        data = {...data,relation_type_no : props?.data?.relation_type_no || null, relation : relation?.value, relation_type : props?.name,customer_id : props?.customer_id,loan_id : props?.loan_id , relation_id : Number(props?.relation_id)}
                    }
                    const newData = moment()
                    const newDate = moment(value.dob)
                    if(!isEmpty(props.name)){
                        if(newData.diff(newDate,'year') > 60){
                            enqueueSnackbar('Age should not be greater than 60 years', { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
                            setOpen(false)
                        }else if(newData.diff(newDate,'year') < 18){
                            enqueueSnackbar('Age should be greater than 18 years', { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
                            setOpen(false)
                        }else{
                            setOpen(true)
                        }
                    }
                    setFormValue({...data})
                }else{
                    setRelationError('Select relation type')
                }
            }
        }
    });
    async function submitForm(value) {
        const data = new FormData();
        data.append('data',JSON.stringify({...value,lead_source:'partners_web'}));
        if(leadId) {
            const response = await axios.post(`${API_URL}/lead/update/${leadId}`,data);
            if(response.data.status === 'success') {
                enqueueSnackbar('Successfully update profile details', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                setAlignment("address")
            }
        }
        else {
            try{
                const response = await axios.post(`${API_URL}/lead`,data);
                if(!isEmpty(response?.data?.data?.error)){
                    enqueueSnackbar(response?.data?.data?.error, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} })
                }
                else if(response.data.status === 'success') {
                    const leadData = response?.data?.data
                    if(isEmpty(props.name)){
                        if(leadData.error){
                            enqueueSnackbar(leadData.error, { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
                        }
                        else{
                            setAlignment("address")
                            setLeadId(leadData?.lead_id)
                            enqueueSnackbar('Successfully added profile details', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                        }
                    }else {
                        if(!includes([RELATION_TYPE.REFERENCE,RELATION_TYPE.NOMINEE], props.name)){
                            setAlignment("address")
                        }else{
                            props.onClose(false)
                        }
                        const relationData = response?.data?.data?.relation
                        setCustomerRelationData({...customerRelationData,...relationData})
                        enqueueSnackbar('Successfully added profile details', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
                    }
                }
            }
            catch(err){
                enqueueSnackbar('Something went worng', { variant:'error',anchorOrigin:{vertical:'top',horizontal:'center'} });
            }
            
        }
    }

    useEffect(() => {
        async function get() {
            const res = await axios.get(`${API_URL}/parts`);
            let allParts = res?.data?.data
            const vehicles = allParts.filter(parts => includes(['L3','L5'],parts.category))
            setPart([...vehicles]);
        }
        if(isEmpty(props.name)){
            get()
        }
        if(context?.user?.company?.category === CATEGORY.DSA){
            getDsaDealer()
        }
    },[context,isAddProduct]);

    const closeDialog = () => {
        setOpen(false);
    };
    
    const submitFormValue = () => {
        submitForm(formvalue)
        setOpen(false);
    };

    const getLeadData = async (leadId) => {
        const data = await axios.get(`${API_URL}/leads/${leadId}`)
        if(data.data.status === 'success') {
            const leadData = data.data.data
            formik.setFieldValue('name',leadData?.name ? leadData?.name : null)
            formik.setFieldValue('mobile',leadData?.mobile ? leadData?.mobile : null)
            formik.setFieldValue('tentative_purchase_date',leadData?.tentative_purchase_date ? leadData?.tentative_purchase_date : null)
            formik.setFieldValue('dob',leadData?.dob ? leadData?.dob : null)
            formik.setFieldValue('vehicle_name',leadData?.vehicle_name ? leadData?.vehicle_name : null)
            formik.setFieldValue('gender',leadData?.gender ? leadData?.gender : null)
            formik.setFieldValue('lead_type',leadData?.lead_type ? leadData?.lead_type : null)
            formik.setFieldValue('source',leadData?.source ? leadData?.source : null)
            formik.setFieldValue('deal_condition',leadData?.deal_condition ? leadData?.deal_condition : null)
            formik.setFieldValue('payment_mode',leadData?.payment_mode || '')
            formik.setFieldValue('exshowroom_price',leadData?.exshowroom_price || '')
            await getDsaDealer()
            const dealerData = dsaDealer?.find(value=> value?.dealer_id === leadData?.dealer_id)
            setDealer(dealerData)
            setData(leadData)
        }
    }
    useEffect(() => {
        function setRelationData(relationData){
            formik.setFieldValue('name',relationData?.name)
            formik.setFieldValue('mobile',relationData?.mobile)
            const applicantRelation = RELATIONS.filter(r=> r.value === relationData.relation)
            setRelation({...applicantRelation[0]})
            formik.setFieldValue('dob',moment(relationData?.dob).format("YYYY-MM-DD"))
            formik.setFieldValue('type',relationData?.type)
            formik.setFieldValue('gender',relationData?.gender)
        }
        if(leadId) {
            getLeadData(leadId)
        }
        else{
            if(!isEmpty(customerRelationData)){
                setRelationData(customerRelationData)
            }
            else if(!isEmpty(props.name) && props.relation_id){
                const relationData = props.data
                setRelationData(relationData)
            }else{
                formik.resetForm()
            }
        }
    },[leadId,props])

    useEffect(() => {
        if(formik.values.vehicle_name) {
            const exshowroomPrice = part?.find(parts => parts.part_name === formik.values.vehicle_name)
            formik.setFieldValue('exshowroom_price',exshowroomPrice?.price || null)
        }
    },[formik.values.vehicle_name])

    useEffect(() => {
        return () => {
            formik.resetForm()
            setDealer({})
        }
    },[])

    const addProduct=()=>{
        // navigate("/dashboard/product")
        setIsAddPropduct(true)
    }

    const getDsaDealer = async() =>{
        const dsaId = context?.user?.company?.company_id
        const res = await axios.get(`${API_URL}/dsa/${dsaId}/clients`)
        if(res?.data?.status === 'success') {
            setDsaDealer(res?.data?.data)
        }
    }
    return (
        <>
            <ConfirmDialog open={open} onClose={closeDialog} submit={()=>submitFormValue()} close={closeDialog} />
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{flexGrow: 1,p: 2}}>
                    <Grid container spacing={2} justifyContent='left'>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                required
                                autoComplete='off'
                                disabled={data.is_pan_verified}
                                fullWidth
                                name="name"
                                type="text"
                                label="Name"
                                inputProps={{style: {textTransform: "uppercase"}}}
                                onBlur={() => formik.setFieldValue('name',(formik.values.name).toUpperCase())}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                required
                                autoComplete='off'
                                fullWidth
                                inputProps={{maxLength: 10}}
                                name="mobile"
                                size='10'
                                maxLength={10}
                                inputMode='text'
                                type={'text'}
                                label="Mobile Number"
                                value={formik.values.mobile ? Number(formik.values.mobile) : ''}
                                onChange={formik.handleChange}
                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                helperText={formik.touched.mobile && formik.errors.mobile}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                required
                                fullWidth
                                select
                                labelId="gender"
                                id="gender"
                                name="gender"
                                label="Gender"
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                                error={formik.touched.gender && Boolean(formik.errors.gender)}
                                helpertext={formik.touched.gender && formik.errors.gender}
                            >
                                <MenuItem value={1}>Male</MenuItem>
                                <MenuItem value={2}>Female</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                required
                                autoComplete='off'
                                fullWidth
                                disabled={data.is_pan_verified}
                                name="dob"
                                type={'date'}
                                label="DOB"
                                className={formik.values.dob === null && Styles.dates}
                                value={formik.values.dob || ''}
                                onChange={formik.handleChange}
                                error={formik.touched.dob && Boolean(formik.errors.dob)}
                                helperText={formik.touched.dob && formik.errors.dob}
                            />
                        </Grid>
                        {isEmpty(props.name) ? <>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                required
                                fullWidth
                                select
                                labelId="lead_type"
                                id="lead_type"
                                name="lead_type"
                                label="Lead Type"
                                value={formik.values.lead_type}
                                onChange={formik.handleChange}
                                error={formik.touched.lead_type && Boolean(formik.errors.lead_type)}
                                helpertext={formik.touched.lead_type && formik.errors.lead_type}
                            >
                                {Object.values(LEAD_TYPE)?.map(leadType =>{return <MenuItem value={leadType}>{leadType}</MenuItem>})}
                            </TextField>
                        </Grid>
                        {context?.user?.company?.category === CATEGORY.DSA &&
                        <Grid item xs={11} sm={6}>
                        <Autocomplete
                            id="dsa_dealer"
                            name='dealer'
                            options={dsaDealer}
                            required
                            getOptionLabel={(option) => upperCase(option?.name) || ''}
                            renderInput={(params) => <TextField {...params} label="Dealer Name" />}
                            value={dealer}
                            onChange={(event,newValue) => {
                                setDealer(newValue)
                            }}
                            />
                        </Grid>
                        }
                        <Grid item xs={11} sm={6}>
                        <Autocomplete
                            id="vehicle_name"
                            name='vehicle_name'
                            options={part}
                            required
                            margin={'noraml'}
                            getOptionLabel={(option) => option?.part_name || ''}
                            renderInput={(params) => <TextField required {...params} label="Vehicle Name" />}
                            value={formik.values?.vehicle_name ? {part_name:formik.values?.vehicle_name} : {part_name:''}}
                            onChange={(event,newValue) => {
                                formik.setFieldValue('vehicle_name',newValue?.part_name)
                            }}
                            noOptionsText={<Button onClick={addProduct}> Add Model</Button>}
                            error={formik.touched.vehicle_name && Boolean(formik.errors.vehicle_name)}
                            helpertext={formik.touched.vehicle_name && formik.errors.vehicle_name}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                fullWidth
                                required
                                autoComplete='off'
                                type={'number'}
                                inputProps={{maxLength: 12}}
                                name="exshowroom_price"
                                label="Ex-Showroom Price"
                                inputMode='numeric'
                                value={formik.values.exshowroom_price}
                                onChange={formik.handleChange}
                                error={formik.touched.exshowroom_price && Boolean(formik.errors.exshowroom_price)}
                                helperText={(formik.touched.exshowroom_price && formik.errors.exshowroom_price) || (formik.values.exshowroom_price && NumberToWordConverter.convert(formik.values.exshowroom_price))}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                name="tentative_purchase_date"
                                type={'date'}
                                label="Tentative Purchase Date "
                                className={formik.values.tentative_purchase_date === null && Styles.dates}
                                value={formik.values.tentative_purchase_date || ''}
                                onChange={formik.handleChange}
                                error={formik.touched.tentative_purchase_date && Boolean(formik.errors.tentative_purchase_date)}
                                helperText={formik.touched.tentative_purchase_date && formik.errors.tentative_purchase_date}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                type={'text'}
                                name="source"
                                label="Source"
                                inputMode='text'
                                value={formik.values.source}
                                onChange={formik.handleChange}
                                error={formik.touched.source && Boolean(formik.errors.source)}
                                helperText={(formik.touched.source && formik.errors.source)}
                            />
                        </Grid>
                        <Grid item xs={11} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="payment_mode">Deal Condition</InputLabel>
                            <Select
                                labelId="payment_mode_label"
                                id="deal_condition"
                                name="deal_condition"
                                value={formik.values.deal_condition}
                                onChange={formik.handleChange}
                                >
                               <MenuItem value={'hot'}>Hot</MenuItem>
                                <MenuItem value={'cold'}>Cold</MenuItem>
                                <MenuItem value={'normal'}>Normal</MenuItem>
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={11} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="payment_mode">Payment Mode</InputLabel>
                            <Select
                                labelId="payment_mode_label"
                                id="payment_mode"
                                name="payment_mode"
                                value={formik.values.payment_mode}
                                onChange={formik.handleChange}
                                >
                                <MenuItem value="cash">Cash</MenuItem>
                                <MenuItem value="finance">Finance</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        </> :

                        props.name === RELATION_TYPE.COAPPICANT ?
                        <>
                        <Grid item xs={11} sm={6}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                select
                                labelId="type"
                                id="type"
                                name="type"
                                label="Type"
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                error={formik.touched.type && Boolean(formik.errors.type)}
                                helpertext={formik.touched.type && formik.errors.type}
                            >
                                <MenuItem value={CO_APPLICANT_TYPES.FINANCIAL}>{CO_APPLICANT_TYPES.FINANCIAL}</MenuItem>
                                <MenuItem value={CO_APPLICANT_TYPES.NON_FINANCIAL}>{CO_APPLICANT_TYPES.NON_FINANCIAL}</MenuItem>
                            </TextField>
                        </Grid> 
                        <Grid item xs={11} sm={6}>
                        <Autocomplete
                            id="relation"
                            name='relation'
                            options={RELATIONS}
                            required
                            getOptionLabel={(option) => option?.label || ''}
                            renderInput={(params) => <TextField required {...params} label="Relation" />}
                            value={relation}
                            onChange={(event,newValue) => {
                                setRelation({...newValue});setRelationError('')
                            }}
                            error={formik.touched.relation && Boolean(formik.errors.relation)}
                            helpertext={formik.touched.relation && formik.errors.relation}
                            />
                             {relationError && <p style={{color:'red'}}>{relationError}</p>}
                        </Grid> 
                        </>
                        :
                        <Grid item xs={11} sm={6}>
                        <Autocomplete
                            id="relation"
                            name='relation'
                            options={RELATIONS}
                            getOptionLabel={(option) => option?.label || ''}
                            renderInput={(params) => <TextField required {...params} label="Relation"  />}
                            value={relation}
                            onChange={(event,newValue) => {
                                setRelation({...newValue});setRelationError('')
                            }}
                            error={relationError && Boolean(relationError)}
                            helpertext={relationError}
                            />
                            {relationError && <p style={{color:'red'}}>{relationError}</p>}
                        </Grid> 
                        }
                        
                    </Grid>
                </Box>
                <Box sx={{m: 2}}>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={8} sm={6}>
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </LoadingButton>

                        </Grid>
                    </Grid>
                </Box>
            </form>
            <Dialog
                id={`addProduct`}
                open={isAddProduct}
                anchorEl={isAddProduct}
                placement="bottom-start"
                maxWidth={'lg'}
                onClose={()=> setIsAddPropduct(false)}
            >
                {/* <ClickAwayListener id={`addProduct`} name={`addProduct`} onClickAway={() => setIsAddPropduct(false)}> */}
                    <div style={{margin:40}}>
                        <AddModel open={isAddProduct} submit={()=>setIsAddPropduct(false)} close={()=>setIsAddPropduct(false)} />
                    </div>
                {/* </ClickAwayListener> */}
            </Dialog>
        </>
    )
}

export default Index