import {useContext, useEffect,useState} from 'react';

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  CardContent,
  Tooltip,
  Button,
  Alert,
  Table,
  TableHead,
  TableCell,
  Dialog,
  ClickAwayListener,
  TableBody,
  TableContainer,
  Badge,
} from '@mui/material';
// components
import Page from '../components/Page';
import axios from 'axios';
import {API_URL, FILE_UPLOAD_TYPE, LEAD_FILE_UPLOAD_TYPE, LEAD_TYPE, LETTER_TYPE, LOAN_SANCTIONED_AND_ABOVE, LOAN_STATES, RELATIONS_FILE_UPLOAD_TYPE, RELATION_TYPE} from '../constant';
import {Link, Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { useSnackbar } from 'notistack';
import AddLead from '../pages/AddLead/index'
import { includes, isEmpty, omit, upperCase, upperFirst } from 'lodash';
import { firstLetetrUpperOfString } from '../utils/helper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileUpload from './FIleUpload';
import DownloadFile from './downloadFile';
import Iconify from '../components/Iconify';
import ConfirmDialog from '../components/confirmDialog';
import { isMobile } from 'react-device-detect';
import CachedIcon from '@mui/icons-material/Cached';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { withStyles } from "@material-ui/core/styles";
import FiForm from './FiForm';
import { LoginContext } from '../context/logincontext';
import PdcChequeDetails from './PdcChequeDetails';
import LoadingSpinner from './LoadingSpinner'
import PreScreeningFiQuestion from './PreScreeningFiQuestion';
// ----------------------------------------------------------------------

const styles = {
  tooltip: {
      maxWidth:'max-content',
      backgroundColor: "black"
  }
};

const CustomTooltip = withStyles(styles)(Tooltip);

function StatusPage(props) {
  const id = props.id
  const [value,setValue] = useState([]);
  const [status,setStatus] = useState();
  const [loanId,setLoanId] = useState();
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [isApplicant, setIsApplicant] = useState(false)
  const [isGuarantor, setIsGuarantor] = useState(false)
  const [isReference, setIsReference] = useState(false)
  const [isNominee, setIsNominee] = useState(false)
  const [labelName , setLabelName] = useState('Customer')
  const [isDoc, setIsDoc] = useState(false)
  const [docData,setDocData] = useState({})
  const [isDownload, setIsDownload] = useState(false)
  const [downloadData,setDownloadData] = useState([])
  const [docType, setDocType] = useState('')
  const [open, setOpen] = useState(false)
  const [checkList, setCheckList] = useState([])
  const [isChecklistRefresh, setIsChecklistRefresh] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false)
  const [isFiForm, setIsFiForm] = useState(false)
  const context = useContext(LoginContext)
  const [userData,setUserData]=useState(context?.user);
  const [fiFormData, setFiFormData] = useState({})
  const [isPdcChequeForm,setIsPdcChequeForm] = useState(false)
  const [loading, setLoading] = useState(false);
  const [isPreScreeningFiForm,setIsPreScreeningFiForm]= useState(false)

  useEffect(() => {
    function getData() {
      const name = firstLetetrUpperOfString(props.name,'-')
      setLabelName(name)
    }
    if(props.name){
      setValue({...props.data})
      getData()
    }
  },[props.name]);

  useEffect(() => {
      async function getData() {
        const res = await axios.get(`${API_URL}/loan/nach_status/${id}`);
        if(res?.data?.status === 'success'){
        setStatus(res?.data?.data[0]?.status);
        }
      }
      if(id){
        getData()
      }
    },[id]);

  useEffect(() => {
    if(id || props?.name){
      get()
    }
  },[id,props?.name]);

  async function get() {
    let leadId = id
    if(props?.name){
      leadId = props?.lead_id
    }
    const res = await axios.get(`${API_URL}/leads/${leadId}`);
    if(res?.data?.status === 'success'){
      const leadData = res?.data?.data
      if(!props?.name){
        setValue(leadData);
        setLoanId(leadData?.loan_id)
        if(leadData?.loan_id){
          await getFiFormDetails(userData?.los_staff_id,leadData?.loan_id)
          getDocumentsCheckList(leadData?.loan_id)
        }
      }else{
        let relationData = !isEmpty(leadData.relations) ? leadData.relations : []
        const leadRelation = relationData?.find(relation=>relation?.relation_id === props?.data?.relation_id)
        props.data.labelName = leadRelation?.name
        setValue({...leadRelation,...leadRelation?.customer_addresses[0],sanctioned_amount : leadData?.sanctioned_amount,state: leadData?.state})
      }
    }
  }

  const checkNach=(id)=>{
    if(loanId){
      navigate(`/dashboard/nach/${id}`)
    }
  }

  const redirect=(id,type)=>{
    switch (type) {
      case "pan_upload":navigate(`/dashboard/lead/${id}/documents`)
      break;
      case "pan_verify":navigate(`/dashboard/lead/${id}/address`)
      break;
      case "aadhaar_upload":navigate(`/dashboard/lead/${id}/documents`)
      break;
      case "aadhaar_verify":navigate(`/dashboard/lead/${id}/address`)
      break;
      case "bank_statement":navigate(`/dashboard/lead/${id}/documents`)
      break;
      case "electricity":navigate(`/dashboard/lead/${id}/documents`)
      break;
      case "profile":navigate(`/dashboard/lead/${id}/details`)
      break;

      default:navigate(`/dashboard/lead/${id}/documents`)
      break;
    }
  }

  const data={
    ...value,
    to_los:true
  }
  delete data.lead_id;
  const checkEligibility=async()=>{
    if(!data?.los_lead_id){
      const response = await axios.post(`${API_URL}/lead/update/${id}`,{data: JSON.stringify(data)});
      if(response?.data?.status==='success'){
        get()
        enqueueSnackbar('We have notified Ascend capital. They will contact you soon.', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
      }
    }else if(!data?.loan_id){
        get()
        enqueueSnackbar('Your loan application is in review. They will contact you soon.', { variant:'info',anchorOrigin:{vertical:'top',horizontal:'center'} });
    }else{
      if(!includes([LOAN_STATES.CIBIL_REQUESTED, LOAN_STATES.CIBIL_GENERATED, LOAN_STATES.FILE_CHECK,LOAN_STATES.LOAN_DISBURSED,LOAN_STATES.LOAN_SANCTIONED],value?.state)){
        if(!fiFormData?.file_url){
          enqueueSnackbar('First fill Fi Form', { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
        }else{
          const response = await axios.get(`${API_URL}/loan/${data?.loan_id}/move_to_ascend`);
          if(response?.data?.status === 'success'){
            get()
            if(response?.data?.data?.error){
              enqueueSnackbar(response?.data?.data?.error, { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
            }else{
              enqueueSnackbar('Your Loan move to file check', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
            }
          }
        }
      }
    }
  }

  const onCloseState=async(isClose)=>{
    setIsFiForm(false)
    setIsDoc(false)
    setIsDownload(false)
    setDocType('')
    setDownloadData([])
    setIsPdcChequeForm(false)
    setIsPreScreeningFiForm(false)
    props.onClose(isClose)
    get()
    isApplicant ? setIsApplicant(isClose) : isGuarantor ? setIsGuarantor(isClose) : isReference ? setIsReference(isClose) : setIsNominee(isClose)
  }

  const onSubmit = async(files=[]) =>{
    setLoading(true);
    const data = new FormData();
    if(!isEmpty(files)){
      files?.forEach((file) => {
        file?.file?.forEach((doc=>{
          data.append(file.fileType,doc);
        }))
      })
    }
    const body = !docData?.relation_id ? {} : docData
    const leadId = !docData?.relation_id ? docData?.lead_id : 0
    data.append('data',JSON.stringify({...body}))
    if(leadId || props.relation_id) {
      let response
      if(isEmpty(files)){
        response = await axios.post(`${API_URL}/lead`,data)
      }else{
        response = await axios.post(`${API_URL}/lead/update/${leadId}`,data);
      }
      if(response?.data?.status === 'success') {
        if(isEmpty(files)){
          const result = await axios.post(`${API_URL}/lead/update/${leadId}`,data);
          if(result?.data?.status === 'success'){
            enqueueSnackbar('Deleted Successfully', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
            setOpen(false)
            props.onClose(false)
          }
        }else{
          setLoading(false);
          get()
          return {status:'success'}
        }
      }else{
        setLoading(false);
      }
    }
  }

  const uploadDoc = (data,type) =>{
    if(!isEmpty(data)){
      setDocType(type)
      setIsDoc(true)
      setDocData(data)
    }
  }

  // open all download button to download file by type if file is not empty and setDocTypeArray is setDownloadData
  const downloadDoc = () =>{
    if(!props?.name){
      const values = omit(value,'relations')
      const allDoc = Object.values(values)
      let files = []
      for(const doc of allDoc){
        if(typeof(doc) === 'object'){
          if(!isEmpty(doc)){
            files.push(doc[0]['file_type'])
          }
        }
      }
      setDownloadData(files)
      setIsDownload(true)
    }else if(!isEmpty(value?.customer_docs)){
        let files = []
        for(const doc of value?.customer_docs){
          if(!isEmpty(doc?.file_path)){
            files.push(doc['file_path'][0]['file_type'])
          }
        }
        setDownloadData(files)
        setIsDownload(true)
    }else{
      enqueueSnackbar('Empty File!', { variant:'warning',anchorOrigin:{vertical:'top',horizontal:'center'} });
    }
  }

  // download file
  const fileDownload = (type) =>{
    if(value?.lead_id){
      return `${API_URL}/lead/${value?.lead_id}/docType/${type}/download`
    }else{
        return`${API_URL}/loan/${value?.loan_id}/relation/${value?.relation_id}/${type}`
    }
  }

  const checkFilePath = (type) =>{
    if(!isEmpty(value?.customer_docs)){
      const docData = value?.customer_docs?.find(doc=> doc.doc_type === type)
      if(isEmpty(docData)){
        return true
      }else{
        return false
      }
    }else{
      return true
    }
  }

  const deleteRelation = (data) =>{
    setDocData(data)
    setOpen(true)
  }

  const onRefresh = async() =>{
    setIsRefresh(true)
    await get()
    setIsRefresh(false)
    props.refresh()
  }

  const downloadLetter = (letterType) =>{
    return `${API_URL}/customer/${value?.customer_id}/loan/${value?.loan_id}/${letterType}/generate_letter`
  }

  const getDocumentsCheckList = async (loanId,isClick = false) =>{
    if(isClick){
      setIsChecklistRefresh(true)
    }
    const result = await axios.get(`${API_URL}/loan/${loanId}/documents_checklist`);
    if(result?.data?.status === 'success'){
      setCheckList(result?.data?.data)
      if(isClick){
        enqueueSnackbar('Checklist refresh successfully', { variant:'success',anchorOrigin:{vertical:'top',horizontal:'center'} });
        setIsChecklistRefresh(false)
      }
    }
  }

  async function getFiFormDetails(staffId,loanId){
    const res= await axios.get(`${API_URL}/staff/${staffId}/loan/${loanId}/fi_form_details`)
    if(res?.data?.status === 'success'){
      const fiFormDetails = res?.data?.data
      setFiFormData(fiFormDetails);
    }
  }

  return (
    <Page>
      <ConfirmDialog text={'Are you sure you want to delete?'} open={open} onClose={()=>setOpen(false)} submit={()=>onSubmit()} close={()=>setOpen(false)} />
      <Container maxWidth>
        <Stack direction="row" sx={{marginBottom:'6px',marginLeft:'30px'}} alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          {labelName} {props?.data?.name} Status 
          </Typography>
           { (value?.is_pan_verified || value?.is_aadhaar_verified || !isEmpty(value?.pan_file_path) || !isEmpty(value?.aadhaar_file_path)) && isEmpty(props.name) && <LoadingButton disabled={includes([LOAN_STATES.CIBIL_REQUESTED, LOAN_STATES.CIBIL_GENERATED, LOAN_STATES.FILE_CHECK,LOAN_STATES.LOAN_DISBURSED,LOAN_STATES.LOAN_SANCTIONED],value?.state)} size="large" color='secondary' variant="contained" onClick={()=>checkEligibility()}>{value?.los_lead_id ? 'Move To File Check' : 'Check Loan Eligibility'} {value?.los_lead_id ? <DriveFileMoveIcon style={{paddingLeft:'10px',fontSize:40}} /> : <TrendingUpIcon style={{paddingLeft:'10px',fontSize:40}} />}</LoadingButton>}
        </Stack>
        <Card>
        <Stack direction="row" sx={{marginBottom:'6px',marginLeft:'30px',width:'max_content'}} alignItems="center" justifyContent="space-between" mb={5}>
            {!props?.name ? <Tooltip title='If any data is missing then refresh the page'><Button disabled={isRefresh} onClick={onRefresh}><CachedIcon style={{paddingRight:'5px',fontSize:'30px'}} />{!isMobile && 'Refresh'}</Button></Tooltip> : <div></div>}
            {!props?.name && value?.loan_id && <CustomTooltip title={<div style={{width:'max-content'}}>{checkList?.map(docCheckList=> { return ( <div style={{color:'white',margin:5,fontSize:15,width:'max-content'}}><KeyboardDoubleArrowRightIcon style={{color:'red',fontSize:30}}>.</KeyboardDoubleArrowRightIcon>{upperFirst(docCheckList?.entity)}.</div>)})}</div>}><Badge style={{marginTop:10}} badgeContent={checkList?.length} color="error"><Button disabled={isChecklistRefresh} onClick={()=>getDocumentsCheckList(value?.loan_id,true)} color='error'><CachedIcon style={{paddingRight:'5px',fontSize:'30px'}} />{'Documents CheckList'}</Button></Badge></CustomTooltip>}
            <div style={{display:'flex',textAlign:'end'}}>{props?.name && <Button disabled={includes(LOAN_SANCTIONED_AND_ABOVE,value?.state)} onClick={()=>deleteRelation({...value,is_active:false})}> <Iconify icon="fluent:delete-16-filled" color={'red'} width={24} height={24} /></Button>}<LoadingButton style={{borderRadius:0}} disabled={props?.name && includes(LOAN_SANCTIONED_AND_ABOVE,value?.state)} size="large" color='secondary' variant="contained" onClick={()=>{props?.name ? setIsApplicant(true) : redirect(value?.lead_id,"profile")}}><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i> Edit</LoadingButton>
            </div>
          </Stack>
          <CardContent>
            <TableContainer sx={{minWidth:100}}>
          <Grid container spacing={0.1} justifyContent='left' columns={{ xs: 20, sm:80 }}>
            <Grid item xs={20} sm={25}>
              <Typography style={{display:'flex',fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green"  gutterBottom>
                Name : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {value?.name}</h4>
              </Typography>
              {(isEmpty(props.name) && value?.loan_id) &&
              <Typography style={{display:'flex', fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green"  gutterBottom>
                Loan Id : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {value?.loan_id}</h4>
              </Typography>}
            </Grid>
            <Grid item xs={20} sm={25}>
            <Typography style={{display:'flex', fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green"  gutterBottom>
                Mobile : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {value?.mobile}</h4>
              </Typography>
              {(isEmpty(props.name) && value?.loan_id) &&
                  <Typography style={{display:'flex', fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green"  gutterBottom>
                  Sanctioned Amount : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',paddingRight:10}}> {value?.sanctioned_amount}</h4>
                </Typography>
                }
              
            </Grid>
            <Grid item xs={20} sm={25}>
              {props?.name ?
                <Typography style={{display:'flex', fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green"  gutterBottom>
                Relation : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {upperCase(value?.relation)}</h4>
              </Typography>
              :
              <Typography style={{display:'flex', fontWeight:'bold',width:'max-content'}} color="green"  gutterBottom>
                  Lead Type : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {value?.lead_type}</h4>
              </Typography>
              }
              {(isEmpty(props.name) && value?.loan_id) && <Typography style={{display:'flex', fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green" gutterBottom>
                Emi : <h4 style={{color:'black',paddingLeft:10,fontWeight:'normal',paddingRight:20,width:'max-content'}}> {value?.emi}</h4>
                Tenure : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal',width:'max-content'}}> {value?.tenure}</h4>
              </Typography>}
            </Grid>
            <Grid item xs={20} sm={25}>
              {!includes([RELATION_TYPE.NOMINEE,RELATION_TYPE.REFERENCE], props.name) && 
                <Typography style={{display:'flex', fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green" gutterBottom>
                  Address : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {value?.address || value?.landmark}</h4>
                </Typography>
              }
              {(isEmpty(props.name) && value?.loan_id) &&
              <Typography style={{display:'flex', fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green"  gutterBottom>
                Loan State : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {upperCase(value?.state)}</h4>
              </Typography>
              }
            </Grid>
          </Grid>
          <Grid container spacing={0.1} justifyContent='left' columns={{ xs: 1, sm:20 }}>
            <Grid item xs={20}>
              { isEmpty(props.name) &&
                <Typography style={{display:'flex', fontWeight:'bold',width:'max-content',justifyContent:"space-around"}} color="green"  gutterBottom>
                  Remarks : <h4 style={{color:'black',paddingLeft:'10px',fontWeight:'normal'}}> {value?.dealer_remarks ? value?.dealer_remarks : value?.state !== 'UPDATE DATA' ? `Your loan file move to ${upperCase(value?.state)}` : 'Your application is in review. They will contact you soon.'}</h4>
                </Typography>
              }
            </Grid>
          </Grid>
          </TableContainer>
          {!includes([RELATION_TYPE.REFERENCE,RELATION_TYPE.NOMINEE,RELATION_TYPE.GUARANTOR,RELATION_TYPE.COAPPICANT], props.name) ? 
              <Grid container spacing={0.1} justifyContent='left' columns={{ xs: 3, sm:30 }}>
              <Grid item xs={10}>
                <Typography justifyContent={"space-between"}>
                {(value?.pan_file_path ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.PAN)}>    
                      <Alert severity="error"  />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.PAN,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Pan Upload  
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.is_pan_verified ===false) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>redirect(value?.lead_id,"pan_verify")}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>redirect(value?.lead_id,"pan_verify")}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Pan Verified
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.aadhaar_file_path ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.AADHAR)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.AADHAR,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Aadhaar Upload
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.is_aadhaar_verified  ===false) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>redirect(value?.lead_id,"aadhaar_verify")}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>redirect(value?.lead_id,"aadhaar_verify")}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Aadhaar Verified
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.udhyog_aadhar ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.UDHYOG_AADHAR)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.UDHYOG_AADHAR,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Udhyog Aadhaar
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.battery_photos) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.BATTERY_PHOTOS)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.BATTERY_PHOTOS,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Battery Photo
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.battery_warranty_card) ?
                  <Tooltip title="Incomplete" >
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.BATTERY_WARRANTY_CARD)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.BATTERY_WARRANTY_CARD,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Battery Warranty Card
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.loan_agreement) ?
                  <Tooltip title="Incomplete" >
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.LOAN_AGREEMENT)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.LOAN_AGREEMENT,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Loan Agreement
                </Typography>
                {value?.loan_id && <><Typography justifyContent={"space-between"}>
                {(value?.profile_photo ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.PROFILE_PHOTO)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.PROFILE_PHOTO,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Profile Photo 
                </Typography>
                {value?.lead_type === LEAD_TYPE.L5 &&
                <Typography justifyContent={"space-between"}>
                {(value?.agreement ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.AGREEMENT)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.AGREEMENT,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Agreement
                </Typography>
                }
                <Typography justifyContent={"space-between"}>
                {(value?.agreement_photo ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Agreement Photo
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.gstr ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.GSTR)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.GSTR,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } GST Return
                </Typography>
                </>}
                {!props?.name && value?.loan_id && 
               <Typography justifyContent={"space-between"}>
               {(value?.tvr_recording ===null) ?   
                 <Tooltip title="Incomplete" >    
                   <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.TVR_RECORDING)}>    
                     <Alert severity="error" />    
                   </Button>    
                 </Tooltip>
                 :
                 <Tooltip title="Complete" >    
                   <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.TVR_RECORDING,'download'),'_blank')}>    
                     <Alert severity="success" />  
                   </Button>    
                 </Tooltip>
               } TVR Recording
               </Typography>
                }
                {!props?.name && includes([LOAN_STATES.LOAN_DISBURSED,LOAN_STATES.LOAN_SANCTIONED],value?.state) && 
                <Typography justifyContent={"space-between"}>
                  <Tooltip title="Complete" >    
                  <Button onClick={()=>window.open(downloadLetter(LETTER_TYPE.SANCTION_LETTER,'download'),'_blank')}>    
                    <Alert severity="success" />  
                  </Button>    
                </Tooltip>
                  Sanction Letter
                </Typography>
                }
                <Typography justifyContent={"space-between"}>
                  <Tooltip title="Click upload to documents" >    
                    <Button onClick={()=>uploadDoc(value,'')}>    
                      <CloudUploadIcon color="secondary" sx={{ fontSize: 40 }} /> 
                    </Button>    
                  </Tooltip>
                   Upload Documents
                </Typography>
              </Grid>

              <Grid item xs={10}>
              <Typography justifyContent={"space-between"}>
                {(value?.bank_statement_path ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.BANK_STATEMENT)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.BANK_STATEMENT,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Bank Statement
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.electricity_bill_path ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.ELECTRICITY_BILL)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.ELECTRICITY_BILL,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Electricity Bill
                </Typography>
                {/* <Typography justifyContent={"space-between"}>
                {(value?.other_kyc_file_path ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.OTHER_ID_PROOF)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.OTHER_ID_PROOF,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Other Kyc
                </Typography> */}
                {value?.loan_id && <><Typography justifyContent={"space-between"}>
                {(value?.nach ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.NACH)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.NACH,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Nach
                </Typography>  
                <Typography justifyContent={"space-between"}>
                {(value?.vehicle_rc ===null) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.VEHICLE_RC)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.VEHICLE_RC,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Vehicle Rc
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.vehicle_photos  ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.VEHICLE_PHOTOS)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.VEHICLE_PHOTOS,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Vehicle Photo
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.sales_photos ===null) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.SALES_PHOTOS)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.SALES_PHOTOS,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Sales Photos
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.itr ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.ITR)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.ITR,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } ITR
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.sales_observation_sheet ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.SALES_OBSERVATION_SHEET)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.SALES_OBSERVATION_SHEET,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Fi Report
                </Typography>
                <Typography justifyContent={"space-between"}>
                  {!fiFormData?.file_url ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>setIsFiForm(true)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>setIsFiForm(true)}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Fi Form
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.remote_file_docs) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.REMOTE_FILE_DOCS)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.REMOTE_FILE_DOCS,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Remote File Docs
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.battery_invoice) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.BATTERY_INVOICE)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.BATTERY_INVOICE,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Battery Inovice
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.pre_screening_form_filled) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>setIsPreScreeningFiForm(true)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>setIsPreScreeningFiForm(true)}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Pre-Screening Fi Question
                </Typography>
                {/* <Typography justifyContent={"space-between"}>
                  {(value?.fi_report ===null) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.FI_REPORT)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.FI_REPORT,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Fi Report
                </Typography> */}
                </>}
              </Grid>

              <Grid item xs={10}>
                {value?.loan_id && <>
                <Typography justifyContent={"space-between"}>
                  {(value?.vehicle_insurance ===null) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.VEHICLE_INSURANCE)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.VEHICLE_INSURANCE,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Vehicle Insurance
                </Typography>
                <Typography justifyContent={"space-between"}>
                  {(value?.vehicle_invoice ===null) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.VEHICLE_INVOICE)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.VEHICLE_INVOICE,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Vehicle Invoice
                </Typography> 
                {value?.lead_type === LEAD_TYPE.L5 &&
                <Typography justifyContent={"space-between"}>
               {(value?.vehicle_quotation ===null) ?   
                 <Tooltip title="Incomplete" >    
                   <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.VEHICLE_QUOTATION)}>    
                     <Alert severity="error" />    
                   </Button>    
                 </Tooltip>
                 :
                 <Tooltip title="Complete" >    
                   <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.VEHICLE_QUOTATION,'download'),'_blank')}>    
                     <Alert severity="success" />  
                   </Button>    
                 </Tooltip>
               } Vehicle Quotation
               </Typography>}
                </>}
                <Typography justifyContent={"space-between"}>
                {(value?.voter_id_file_path ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.VOTER_ID)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.VOTER_ID,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Voter Id
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.driving_license_file_path ===null) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.DRIVING_LICENCE)}>    
                      <Alert severity="error"  />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.DRIVING_LICENCE,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Driving License
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.pdc_cheque) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>setIsPdcChequeForm(true)}>    
                      <Alert severity="error"  />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>setIsPdcChequeForm(true)}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } PDC Cheque
                </Typography>
                {value?.loan_id && <>
                <Typography justifyContent={"space-between"}>
                  {(value?.balance_sheet ===null) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.BALANCE_SHEET)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.BALANCE_SHEET,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Balance Sheet
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(value?.application_form ===null) ?   
                  <Tooltip title="Incomplete" > 
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.APPLICATION_FORM)}>    
                      <Alert severity="error" />    
                    </Button>
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.APPLICATION_FORM,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>
                  </Tooltip>
                } Application Form
                </Typography>
                {!props?.name && includes([LOAN_STATES.LOAN_DISBURSED,LOAN_STATES.LOAN_SANCTIONED],value?.state) &&
                <Typography justifyContent={"space-between"}>
                  <Tooltip title="Download" >    
                  <Button onClick={()=>window.open(downloadLetter(LETTER_TYPE.DELIVERY_ORDER,'download'),'_blank')}>    
                    <Alert severity="success"/>  
                  </Button>    
                </Tooltip>
                 Delivery Order
                </Typography>
                }
                <Typography justifyContent={"space-between"}>
                  {(value?.other_documents ===null) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.OTHER_DOCS)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.OTHER_DOCS,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Other Documents
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.sale_deed) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.SALE_DEED)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.SALE_DEED,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Sale Deed
                </Typography>
                <Typography justifyContent={"space-between"}>
                {(!value?.margin_money_receipt) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.MARGIN_MONEY_RECEIPT)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.MARGIN_MONEY_RECEIPT,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Margin Money Receipt
                </Typography>
                </>}
              </Grid>
              </Grid>
            :
            <>
          {!includes([RELATION_TYPE.REFERENCE], props.name) &&
              <Grid container spacing={0.1} justifyContent='left' columns={{ xs: 3, sm:30 }}>
              <Grid item xs={10}>
                <Typography>
                {checkFilePath(FILE_UPLOAD_TYPE.PAN) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.PAN)}>    
                      <Alert severity="error"  />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.PAN,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Pan Upload
                </Typography>
                <Typography>
                {checkFilePath(FILE_UPLOAD_TYPE.AADHAR) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.AADHAR)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.AADHAR,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Aadhaar Upload 
                </Typography>
                {props.name!== RELATION_TYPE.NOMINEE &&<Typography justifyContent={"space-between"}>
               {checkFilePath(FILE_UPLOAD_TYPE.TVR_RECORDING) ?  
                 <Tooltip title="Incomplete" >    
                   <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.TVR_RECORDING)}>    
                     <Alert severity="error" />    
                   </Button>    
                 </Tooltip>
                 :
                 <Tooltip title="Complete" >    
                   <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.TVR_RECORDING,'download'),'_blank')}>    
                     <Alert severity="success" />  
                   </Button>    
                 </Tooltip>
               } TVR Recording
               </Typography>}
               {props.name=== RELATION_TYPE.COAPPICANT &&<Typography>
                  {checkFilePath(FILE_UPLOAD_TYPE.APPLICATION_FORM) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.COAPPLICANT_APPLICATION_FORM)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.COAPPLICANT_APPLICATION_FORM,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Co-Applicant Application Form
                </Typography>}
               {props.name=== RELATION_TYPE.GUARANTOR &&<Typography>
                  {checkFilePath(FILE_UPLOAD_TYPE.SALES_PHOTOS) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.GUARANTOR_SALES_PHOTO)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.GUARANTOR_SALES_PHOTO,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } GUARANTOR SALES PHOTO
                </Typography>}
                {props.name!== RELATION_TYPE.NOMINEE &&<Typography>
                  <Tooltip title="Click upload to documents" >    
                    <Button onClick={()=>uploadDoc(value)}>    
                      <CloudUploadIcon color="secondary" sx={{ fontSize: 40 }} /> 
                    </Button>    
                  </Tooltip>
                  Upload Documents
                </Typography>}
              </Grid>

              <Grid item xs={10}>
              {props.name!== RELATION_TYPE.NOMINEE &&<Typography>
                {checkFilePath(FILE_UPLOAD_TYPE.PROFILE_PHOTO) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.PROFILE_PHOTO)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.PROFILE_PHOTO,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Profile Photo
                </Typography>}
                {props.name!== RELATION_TYPE.NOMINEE &&<Typography>
                {checkFilePath(FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.AGREEMENT_PHOTOS,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Agreement Photo
                </Typography>}
                {props.name!== RELATION_TYPE.NOMINEE &&<Typography>
                {checkFilePath(FILE_UPLOAD_TYPE.NACH) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.NACH)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.NACH,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Nach
                </Typography> }
                {props.name=== RELATION_TYPE.GUARANTOR &&<Typography>
                  {checkFilePath(FILE_UPLOAD_TYPE.APPLICATION_FORM) ?
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.GUARANTOR_APPLICATION_FORM)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.GUARANTOR_APPLICATION_FORM,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Guarantor Application Form
                </Typography>}
              </Grid>

              <Grid item xs={10}>
                <Typography>
                {checkFilePath(FILE_UPLOAD_TYPE.VOTER_ID) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.VOTER_ID)}>    
                      <Alert severity="error" />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.VOTER_ID,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Voter Id
                </Typography>
                <Typography>
                {checkFilePath(FILE_UPLOAD_TYPE.DRIVING_LICENCE) ?   
                  <Tooltip title="Incomplete" >    
                    <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.DRIVING_LICENCE)}>    
                      <Alert severity="error"  />    
                    </Button>    
                  </Tooltip>
                  :
                  <Tooltip title="Complete" >    
                    <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.DRIVING_LICENCE,'download'),'_blank')}>    
                      <Alert severity="success" />  
                    </Button>    
                  </Tooltip>
                } Driving License
                </Typography>
                {props.name!== RELATION_TYPE.NOMINEE &&<Typography>
                  {checkFilePath(FILE_UPLOAD_TYPE.OTHER_DOCS) ?   
                    <Tooltip title="Incomplete" >    
                      <Button onClick={()=>uploadDoc(value,FILE_UPLOAD_TYPE.OTHER_DOCS)}>    
                        <Alert severity="error"/>    
                      </Button>    
                    </Tooltip>
                    :
                    <Tooltip title="Complete" >    
                      <Button onClick={()=>window.open(fileDownload(FILE_UPLOAD_TYPE.OTHER_DOCS,'download'),'_blank')}>    
                        <Alert severity="success"/>  
                      </Button>    
                    </Tooltip>
                  } Other Documents
                </Typography>}
              </Grid>
              </Grid>
            }
            </>
          }
          </CardContent>
        </Card>
        {(value.loan_id && isEmpty(props.name)) && 
          <>
            <Stack direction="row" sx={{marginBottom:'6px',marginLeft:'30px'}} alignItems="center" justifyContent="space-between" mb={5}>
              <LoadingButton size="large" color='secondary' variant="contained" onClick={()=>{setIsApplicant(true)}}><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i> Co Applicant</LoadingButton>
              <LoadingButton size="large" color='secondary' variant="contained" onClick={()=>{setIsGuarantor(true)}}><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i> Guarantor</LoadingButton>
              <LoadingButton size="large" color='secondary' variant="contained" onClick={()=>{setIsReference(true)}}><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i> Reference</LoadingButton>
              <LoadingButton size="large" color='secondary' variant="contained" onClick={()=>{setIsNominee(true)}}><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i> Nominee</LoadingButton>
            </Stack>
            <Stack direction="row" sx={{marginBottom:'6px',marginLeft:'30px'}} alignItems="center" justifyContent="space-between" mb={5}>
              <LoadingButton size="large" color='secondary' variant="contained" onClick={()=>navigate(`/dashboard/lead/${value.lead_id}/nach`)}><i className="bi bi-person-plus"></i>Activate NACH</LoadingButton>
              <LoadingButton sx={{marginLeft:'0'}} size="large" color='secondary' variant="contained" onClick={()=>navigate(`/dashboard/lead/${value.lead_id}/tvr`)}><i style={{paddingRight:'10px'}} className="bi bi-person-plus"></i>Create TVR</LoadingButton>
              <LoadingButton size="large"/>
              <LoadingButton size="large"/>
            </Stack>
          </>
        }
      </Container>
      {(isApplicant || isGuarantor || isNominee || isReference) && 
       <div>

       <Dialog
         id={`applicant`}
         open={isApplicant || isGuarantor || isNominee || isReference}
         anchorEl={isApplicant || isGuarantor || isNominee || isReference}
         placement="bottom-start"
         maxWidth={'lg'}
         onClose={()=> onCloseState(false)}
       >
         <ClickAwayListener id={`applicant`} name={`applicant`} onClickAway={() => onCloseState(false)}>
         <AddLead {...{id : null, data: props.data, relation_id : props.relation_id,  name : !isEmpty(props.name) ? props.name : isApplicant ? RELATION_TYPE.COAPPICANT : isGuarantor ? RELATION_TYPE.GUARANTOR : isReference ? RELATION_TYPE.REFERENCE : RELATION_TYPE.NOMINEE, customer_id : value?.customer_id,loan_id : data.loan_id || props.loan_id}} onClose={(isClose)=> onCloseState(isClose)}/>
         </ClickAwayListener>
       </Dialog>
     </div>
      }
      {(isDoc || isDownload) && 
       <Dialog
       id={`documents`}
       open={isDoc || isDownload}
       anchorEl={isDoc || isDownload}
       placement="bottom-start"
       fullWidth
       onClose={()=> onCloseState(false)}
     >
       <ClickAwayListener id={`documents`} name={`documents`} onClickAway={() => onCloseState(false)}>
          {isDoc ? <FileUpload docType={!props?.name ? [...LEAD_FILE_UPLOAD_TYPE] : [...RELATIONS_FILE_UPLOAD_TYPE] } doc_type={docType} onClose={()=> onCloseState(false)} submit={(filePath)=>onSubmit(filePath)} />
          :
          <DownloadFile onClose={()=> onCloseState(false)} data={downloadData} download={(type)=>fileDownload(type)} />
          }
       </ClickAwayListener>
     </Dialog>
      }
      {isFiForm && 
       <Dialog
       id={`fi_form`}
       open={isFiForm}
       anchorEl={isFiForm}
       placement="bottom-start"
       maxWidth={'lg'}
       onClose={()=> setIsFiForm(false)}
     >
       <ClickAwayListener id={`fi_form`} name={`fi_form`} onClickAway={()=>setIsFiForm(false)}>
          <FiForm data={value} onClose={()=>onCloseState(false)} />
       </ClickAwayListener>
     </Dialog>
      }
      {isPdcChequeForm && 
       <Dialog
       id={`pdc_cheque`}
       open={isPdcChequeForm}
       anchorEl={isPdcChequeForm}
       placement="bottom-start"
       maxWidth={'lg'}
       onClose={()=> setIsPdcChequeForm(false)}
     >
       <ClickAwayListener id={`pdc_cheqiue`} name={`pdc_cheqiue`} onClickAway={()=>setIsPdcChequeForm(false)}>
          <PdcChequeDetails data={value} onClose={()=>onCloseState(false)} />
       </ClickAwayListener>
      </Dialog>
      }
      {isPreScreeningFiForm && 
      <Dialog
       id={`pre_screening_form`}
       open={isPreScreeningFiForm}
       anchorEl={isPreScreeningFiForm}
       placement="bottom-start"
       maxWidth={'lg'}
       onClose={()=> setIsPreScreeningFiForm(false)}
      >
      <ClickAwayListener id={`pre_screening_form`} name={`pre_screening_form`} onClickAway={()=>setIsPdcChequeForm(false)}>
        <PreScreeningFiQuestion data={value} onClose={()=>onCloseState(false)} />
      </ClickAwayListener>
      </Dialog>
      }
      {loading && <LoadingSpinner />}
    </Page>
  );
}

export default function Status(){
  const{id}=useParams()
  const [leadData, setLeadData] = useState([])

  async function get() {
    const res = await axios.get(`${API_URL}/leads/${id}`);
    if(res?.data?.status === 'success'){
      setLeadData(res?.data?.data);
    }
  }
  useEffect(() => {
    if(id){
      get()
    }
  },[id]);

  const onRefresh = async() =>{
    setLeadData([])
    await get()
  }

  const onClose = async(isClose) =>{
    // setLeadData([])
    await get()
  }
  let coApplicant = []
  let guarantor = []
  let reference = []
  let nominee = []
  let relationData = !isEmpty(leadData?.relations) ? leadData?.relations : []
  if(!isEmpty(relationData)){
    coApplicant=  relationData?.filter(relation=> relation?.relation_type === RELATION_TYPE.COAPPICANT)
    guarantor = relationData?.filter(relation=> relation?.relation_type === RELATION_TYPE.GUARANTOR)
    reference = relationData?.filter(relation=> relation?.relation_type === RELATION_TYPE.REFERENCE)
    nominee = relationData?.filter(relation=> relation?.relation_type === RELATION_TYPE.NOMINEE)
    relationData = [...coApplicant, ...guarantor, ...reference, ...nominee]
  }
  const loanId = leadData?.loan_id

    return (
      <>
        <div style={{paddingBottom:20}}>
          <StatusPage refresh={onRefresh} onClose={(isClose)=>onClose(isClose)} {...{id : id,loan_id: loanId, name : ''}} />
        </div>
        { !isEmpty(relationData) &&
        relationData?.map(relation =>
            <div style={{paddingBottom:20}}>
              <StatusPage onClose={(isClose)=>onClose(isClose)} {...{id : null, data : relation?.customer_addresses ? {...relation,...relation?.customer_addresses[0],sanctioned_amount:leadData?.sanctioned_amount,state: leadData?.state} : {...relation,sanctioned_amount:leadData?.sanctioned_amount,state: leadData?.state} ,relation_id : relation?.relation_id, customer_id : relation?.customer_id, name : relation?.relation_type, loan_id : relation?.loan_id, lead_id : id}}/>
            </div>
          )
        }
      </>
    )
}
